import React from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import email from '../../img/email.svg';


import './index.scss';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Contact | STINGS</title>
          <meta property="og:title" content="Contact | STINGS" />
          <meta name="twitter:title" content="Contact | STINGS"></meta>
        </Helmet>
        <div className="content">
          <div className="content__inner">
            <section className="contact">
              <div className="section-heading-blue">
                <h2>CONTACT</h2>
              </div>
              <div className="contact-content">
                <p>下記フォームからメッセージを投稿してください</p>
                <form
                  className="contact-form"
                  name="contact"
                  method="POST"
                  action="/contact/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <ul className="contact-form-list">
                    <li>
                      <label htmlFor={'last-name'}>姓（必須）</label>
                      <input
                        className="input"
                        type={'text'}
                        name={'last-name'}
                        onChange={this.handleChange}
                        id={'last-name'}
                        required={true}
                        placeholder="鈴木"
                      />
                    </li>
                    <li>
                      <label htmlFor={'first-name'}>名（必須）</label>
                      <input
                        className="input"
                        type={'text'}
                        name={'first-name'}
                        onChange={this.handleChange}
                        id={'first-name'}
                        required={true}
                        placeholder="太郎"
                      />
                    </li>
                    <li>
                      <label htmlFor={'company-name'}>会社名/団体名</label>
                      <input
                        className="input"
                        type={'text'}
                        name={'company-name'}
                        onChange={this.handleChange}
                        id={'company-name'}
                        placeholder="株式会社STINGS"
                      />
                    </li>
                    <li>
                      <label htmlFor={'company-department'}>部署名</label>
                      <input
                        className="input"
                        type={'text'}
                        name={'company-department'}
                        onChange={this.handleChange}
                        id={'company-department'}
                        placeholder="営業部"
                      />
                    </li>
                    <li>
                      <label htmlFor={'email'}>メールアドレス（必須）</label>
                      <input
                        className="input"
                        type={'email'}
                        name={'email'}
                        onChange={this.handleChange}
                        id={'email'}
                        required={true}
                        placeholder="stings@mail.com"
                      />
                    </li>
                    <li>
                      <label htmlFor={'telephone'}>電話番号（必須）</label>
                      <input
                        className="input"
                        type={'tell'}
                        name={'telephone'}
                        onChange={this.handleChange}
                        id={'telephone'}
                        required={true}
                        placeholder="STINGS太郎"
                        placeholder="08012345678"
                      />
                    </li>
                    <li>
                      <label htmlFor={'category'}>
                        お問い合わせ種類（必須）
                      </label>
                      <select
                        onChange={this.handleChange}
                        name={'category'}
                        id={'category'}
                        required={true}
                      >
                        <option value="">
                          お問い合わせ内容を選択してください
                        </option>
                        <option value="Twitterマーケティング">
                          Twitterマーケティング
                        </option>
                        <option value="Instagramマーケティング">
                          Instagramマーケティング
                        </option>
                        <option value="漫画家・イラストレーターのディレクション">
                          漫画家・イラストレーターのディレクション
                        </option>
                        <option value="キャラクターIPコンテンツの業務提携">
                          キャラクターIPコンテンツの業務提携
                        </option>
                      </select>
                    </li>
                    <li>
                      <label htmlFor={'message'}>
                        お問い合わせ内容（必須）
                      </label>
                      <textarea
                        className="textarea"
                        name={'message'}
                        onChange={this.handleChange}
                        id={'message'}
                        required={true}
                        cols="40"
                        rows="10"
                        placeholder="お問い合わせ内容を入力してください"
                      ></textarea>
                    </li>
                  </ul>
                  <div className="submit">
                    <p>
                      <a href="/privacy-policy" target="_blank">
                        プライバシーポリシー
                      </a>
                      に同意の上送信します。
                    </p>
                    <input
                      type="submit"
                      value="送信する"
                      className="submit-btn"
                    />
                  </div>
                </form>
                <div className="notice">
                  <p>ご利用中のブラウザによってはお問い合わせフォームが正常に送信できない場合がございます。お急ぎの場合は下記のメールアドレスまで直接お問い合わせ内容をご送信ください。</p>
                  <img src={email} alt="email" />
                </div>
              </div>
            </section>   
          </div>
        </div>
      </Layout>
    );
  }
}
